/* Contact Section Styling */
.contact {
  position: relative;
  background: #f0f4f8;
  padding: 6rem 3rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  overflow-x: hidden;
}

.contact-container {
  text-align: center;
  max-width: 1000px;
  width: 100%;
}

.contact-heading {
  font-size: 3rem;
  margin-bottom: 3rem;
  font-weight: 800;
  color: #2196f3;
  letter-spacing: 1px;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.contact-form-group {
  display: flex;
  flex-direction: column;
}

.contact-form-group label {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.contact-form-group input,
.contact-form-group textarea {
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
}

.contact-form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: #2196f3;
  color: white;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #1565c0;
}

.form-status {
  margin-top: 1rem;
  font-size: 1rem;
  color: #2196f3;
}

/* Scroll Down Indicator */
.scroll-down-indicator {
  margin-top: 5rem;
  font-size: 2.5rem;
  animation: bounce 1.5s infinite;
  color: #2196f3;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-heading {
    font-size: 2.8rem;
  }

  .contact-form-group label {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .contact-heading {
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .contact-form {
    gap: 1rem;
  }

  .contact-form-group label {
    font-size: 1.1rem;
  }

  .submit-button {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .contact-heading {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .contact-form {
    gap: 0.8rem;
  }

  .contact-form-group label {
    font-size: 1rem;
  }

  .submit-button {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .scroll-down-indicator {
    font-size: 2rem;
  }
}
