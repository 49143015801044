/* Estilos generales del footer */
.footer {
  background-color: #1a1a1a;
  color: white;
  padding: 2rem 5rem;
  margin-top: 2rem;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.footer-section {
  flex: 1;
}

.footer-section h4 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.footer-section p, .footer-section ul, .footer-section a {
  font-size: 0.9rem;
  color: #bbb;
  text-decoration: none;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section ul li a:hover {
  color: #ffbb33;
}

.social-links a {
  margin-right: 1rem;
  color: #bbb;
  font-size: 1rem;
}

.social-links a:hover {
  color: #ffbb33;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.8rem;
  color: #bbb;
}

.footer-image {
  width: 100%; /* Asegura que la imagen ocupe el ancho de su contenedor */
  max-width: 150px; /* Limita el tamaño máximo de la imagen */
  height: auto; /* Mantiene la proporción de la imagen */
  margin-top: 1rem; /* Añade espacio entre el texto y la imagen */
  margin-bottom: 1rem; /* Añade espacio debajo de la imagen */
}

/* Estilos responsivos */
@media (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  .footer-section h4 {
    font-size: 1.1rem;
  }

  .footer-section p, .footer-section ul, .footer-section a {
    font-size: 0.85rem;
  }

  .social-links a {
    font-size: 1.2rem;
    margin-right: 1.5rem;
  }

  .footer-bottom {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 1.5rem;
  }

  .footer-container {
    padding: 0;
    gap: 1rem;
  }

  .footer-section h4 {
    font-size: 1rem;
  }

  .footer-section p, .footer-section ul, .footer-section a {
    font-size: 0.8rem;
  }

  .footer-section ul li {
    margin-bottom: 0.25rem;
  }

  .footer-bottom {
    font-size: 0.8rem;
  }

  .social-links a {
    margin-right: 0.8rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .footer-container {
    padding: 0;
    gap: 1rem;
  }

  .footer-section {
    width: 100%;
    text-align: center;
  }

  .footer-bottom {
    font-size: 0.75rem;
  }

  .social-links a {
    font-size: 1.2rem;
    margin-right: 0.8rem;
  }

  .footer {
    padding: 1rem;
  }

  .footer-section ul li {
    margin-bottom: 0.2rem;
  }
}
