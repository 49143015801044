/* Global Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.full-width-image-GrupoGalvez {
  width: 100%;
  overflow: hidden;
  height: 33rem;
  display: block;
}

.full-width-image-element {
  width: 100%;
  height: 33rem;
  display: block;
}


/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff6200;
  padding: 5rem;
  color: white;
  flex-wrap: wrap; /* For responsiveness */
}

.hero-content {
  max-width: 50%;
  padding-right: 2rem; /* Space between text and image */
}

.hero-heading {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.hero-subheading {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: white;
}

.cta-button {
  padding: 12px 24px;
  font-size: 1.25rem;
  background-color: #ffbb33;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
  background-color: #ff9c00;
  transform: scale(1.05);
}

.hero-image {
  flex: 1; /* Ensures the image adjusts to available space */
  padding-left: 15rem;
}

.hero-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.features {
  padding: 4rem 5rem;
  background-color: #fff;
}

.features-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  flex-wrap: wrap;
}

.feature-card {
  text-align: center;
  background-color: #f4f4f9;
  padding: 2rem;
  border-radius: 10px;
  flex-basis: calc(33.33% - 2rem);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card img {
  max-width: 100px;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature-card p {
  font-size: 1rem;
  color: #555;
}

.video-section {
  padding: 4rem 5rem;
  background-color: #f4f4f9;
}

.video-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.video-container {
  display: flex;
  justify-content: center;
}

.testimonials {
  padding: 4rem 5rem;
  background-color: #fff;
}

.testimonials-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.testimonial-cards {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  flex-wrap: wrap;
}

.testimonial-card {
  text-align: center;
  background-color: #f4f4f9;
  padding: 2rem;
  border-radius: 10px;
  flex-basis: calc(50% - 2rem);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-card p {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.testimonial-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.testimonial-card .role {
  font-size: 1rem;
  color: #777;
}

.benefits {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.benefits-heading {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
}

.benefits-list li {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.pricing {
  padding: 4rem 5rem;
  background-color: #f4f4f9;
}

.pricing-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.pricing-cards {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  flex-wrap: wrap;
}

.pricing-card {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  flex-basis: calc(33.33% - 2rem);
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.pricing-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.pricing-card .price {
  font-size: 2rem;
  color: #ff6200;
  margin-bottom: 2rem;
}

.cta-section {
  padding: 4rem 5rem;
  background-color: #ff6200;
  text-align: center;
  color: white;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.cta-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.cta-section .cta-button {
  padding: 12px 24px;
  background-color: #ffbb33;
  color: white;
  border-radius: 5px;
}

.cta-section .cta-button:hover {
  background-color: #ff9c00;
  transform: scale(1.05);
}

.faq {
  padding: 4rem 5rem;
  background: #f9f9fc;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #333;
}

.faq-items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.faq-item {
  background: white;
  padding: 1.5rem 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.faq-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.faq-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.faq-item p {
  font-size: 1rem;
  color: #555;
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    padding: 2rem;
  }

  .hero-content {
    max-width: 100%;
    text-align: center;
  }

  .hero-heading {
    font-size: 2.5rem;
  }

  .hero-subheading {
    font-size: 1.25rem;
  }

  .cta-button {
    font-size: 1rem;
  }

  .features {
    padding: 3rem 2rem;
  }

  .feature-cards {
    flex-direction: column;
    align-items: center;
  }

  .feature-card {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }

  .video-section {
    padding: 3rem 2rem;
  }

  .testimonials {
    padding: 3rem 2rem;
  }

  .testimonial-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing {
    padding: 3rem 2rem;
  }

  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    flex-basis: 100%;
    margin-bottom: 2rem;
  }

  .faq {
    padding: 3rem 2rem;
  }

  .faq-items {
    flex-direction: column;
    align-items: center;
  }

  .faq-item {
    flex-basis: 100%;
  }
}
