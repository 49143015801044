.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ff6200;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  position: relative;
}

.navbar-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
}

.navbar-logo-img {
  height: 3.5rem;
  width: 4rem;
}

.navbar-links {
  display: flex;
  gap: 1.5rem;
  list-style: none;
  flex-grow: 1; /* This will push navbar items to center */
  justify-content: center; /* Ensures the links are centered */
  margin: 0 auto; /* Centers the ul */
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-links li a:hover {
  color: #ffbb33;
  transform: scale(1.1);
}

.navbar-cta .btn-primary {
  padding: 0.5rem 1.5rem;
  background-color: #ffbb33;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar-cta .btn-primary:hover {
  background-color: #ff9c00;
  transform: scale(1.05);
}

/* Sticky navbar background change on scroll */
.navbar.sticky {
  background-color: #d85a00;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .navbar-logo {
    position: relative;
    left: 0;
  }

  .navbar-logo-img {
    width: 100px;
  }

  .navbar-links {
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    margin-top: 1rem;
    display: none;
    background-color: #ff6200;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    padding: 1rem 0;
    text-align: center;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links li a {
    font-size: 1.25rem;
    padding: 0.5rem 0;
    width: 100%;
  }

  .navbar-cta .btn-primary {
    width: 100%;
    padding: 0.75rem 1.5rem;
    text-align: center;
    margin-top: 1rem;
  }

  .navbar-toggle {
    display: block;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
  }
}
